import { Fragment } from 'react';

import { Div } from '@playbooks/interface/html';
import { BorderLink } from '@playbooks/interface/links';
import { FollowBtn } from 'components/follow/follow-btn';
import { useAccount } from 'contexts';

export const UserCardActions = ({ type, user, rootLink }) => {
	const account = useAccount();
	const isOwner = account.account?.id === user.id && account.type === 'User';
	const props = { user, isOwner, rootLink };

	// Render
	switch (type) {
		case 'grid':
		case 'profile':
			return <UserGridCardActions {...props} />;

		case 'list':
			return <UserListCardActions {...props} />;
	}
};

export const UserGridCardActions = ({ user, isOwner, rootLink }) => (
	<Div display='flex-between' space='space-x-4' width='w-full'>
		{isOwner ? (
			<BorderLink prevIcon='gear' href={`${rootLink}/settings`} className='w-full'>
				Settings
			</BorderLink>
		) : (
			<FollowBtn type='profile' model={user} rootApi={`/users/${user?.uuid}`} />
		)}
	</Div>
);

export const UserListCardActions = ({ user, isOwner, rootLink }) => (
	<Fragment>
		{isOwner ? (
			<BorderLink size='icon' icon='gear' href={`${rootLink}/settings`} />
		) : (
			<FollowBtn type='card' model={user} rootApi={`/users/${user?.uuid}`} />
		)}
	</Fragment>
);
