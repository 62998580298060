import { Fragment } from 'react';

import { Badge } from '@playbooks/interface/badges';
import { Span } from '@playbooks/interface/html';
import { FadIcon } from '@playbooks/interface/icons';
import { useAccount } from 'contexts';

const TeamBadges = ({ type = 'card', team, ...props }) => {
	const account = useAccount();

	return (
		<Fragment>
			{!team.isBasic && (
				<Badge>
					<Span>{team.role}</Span>
				</Badge>
			)}
			{team.visibility === 'private' && (
				<Badge>
					<FadIcon icon='lock' />
				</Badge>
			)}
			{team.isVerified && (
				<Badge>
					<FadIcon icon='circle-check' color='green-500' />
				</Badge>
			)}
			{team.status === 'disabled' && (
				<Badge>
					<Span>{team.status}</Span>
				</Badge>
			)}
			{type === 'hero' && account.type === 'Team' && account.account.uuid === team.uuid && <Badge>This is you</Badge>}
		</Fragment>
	);
};

export { TeamBadges };
